html {
  touch-action: manipulation;
}

body {
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
}

#root {
  height: 100%;
}

.loading-box {
  margin-top: 15vh;
  width: 100%;
}

.home-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

.home-container > .adm-space-item {
  margin-bottom: 16px;
  width: 100%;
}

.home-container .banner {
  width: 100vw;
  height: 66.6667vw;
}

.home-container .adm-card {
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  height: 160px;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  text-align: center;
  width: calc(100% - 32px);
}

.home-container .adm-card:active {
  filter: brightness(0.9);
}

.home-container .audio-card {
  background-color: #05347a;
}

.home-container .replay-card {
  background-color: #b57008;
}

.home-container .adm-card p {
  line-height: 1.6;
  margin: 0;
  user-select: none;
}

.login-popup .prompt-panel .adm-list-item-content-main {
  color: #666666;
  font-size: 15px;
}

.login-popup .prompt-panel .adm-list-item-content-main .antd-mobile-icon {
  margin-right: 4px;
}

.audio-index-page {
  width: 100vw;
  height: 100%;
}

.audio-index-page > .adm-space-item {
  margin-bottom: 0;
}

.audio-index-page > .adm-space-item > .adm-list .adm-list-header {
  font-size: 14px;
}

.audio-index-page .page-content {
  display: flex;
  flex-direction: column;
  max-height: calc(calc(var(--vh, 1vh) * 100) - 38.5px);
  max-height: calc(100dvh - 38.5px);
  overflow-y: auto;
}

.audio-index-page .page-content .adm-list-body {
  font-size: 15px;
}

.audio-index-page .adm-collapse-panel-content {
  color: #666666;
}

.audio-index-page .adm-collapse-panel-content-inner > .adm-list-item > .adm-list-item-content > .adm-list-item-content-main {
  padding: 0;
}

.audio-index-page .audio-list > .adm-list-body {
  border-style: none;
}

.audio-page {
  background: linear-gradient(to bottom, rgb(238, 242, 243), rgb(142, 158, 171));
  width: 100vw;
  height: 100%;
}

@keyframes record-player-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.record-player-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  padding-top: 8vw;
}

.record-player {
  position: relative;
  width: 78vw;
  height: 78vw;
}

.record-player .back,
.record-player .front {
  position: absolute;
  top: 0;
  left: 0;
  width: 78vw;
  height: 78vw;
}

.record-player .logo {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 11.5vw;
  left: 11.5vw;
  width: 55vw;
  height: 55vw;
}

.record-player .logo.spinning {
  animation: record-player-spin infinite 16s linear;
}

.record-player-container .audio-title {
  margin: 1.2em 0 0;
  text-align: center;
}

.record-player-container .ad-text {
  color: #666666;
  margin: 1.2em 0.5em;
  text-align: center;
}

.control-bar {
  align-items: center;
  box-sizing: border-box;
  color: #262626;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.control-bar .adm-slider {
  width: 82vw;
}

.control-bar .h-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.control-bar .time-text {
  font-size: 12px;
  margin-top: 5px;
  padding: 0;
  user-select: none;
  width: 91vw;
}

.control-bar .button {
  cursor: pointer;
}

.control-bar .button:active {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.control-bar .speed-buttons {
  align-items: center;
  background-color: transparent;
  margin: 5vw 0 4vw;
  padding: 0;
  width: 80vw;
}

.control-bar .speed-button-box {
  width: 24vw;
}

.control-bar .speed-button {
  border-radius: 50%;
  position: relative;
  width: 10vw;
  height: 10vw;
}

.control-bar .speed-button .adm-image {
  position: absolute;
  top: 0.4vw;
  left: -0.15vw;
}

.control-bar .speed-button.right-button .adm-image {
  left: 0.9vw;
}

.control-bar .speed-button .adm-image-img {
  width: 9.4vw;
  height: 9.4vw;
}

.control-bar .speed-button.left-button .adm-image-img {
  transform: scaleX(-1);
}

.control-bar .speed-button span {
  font-size: 3vw;
  line-height: 10vw;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  user-select: none;
}

.control-bar .speed-button.left-button span {
  left: 0.5vw;
}

.control-bar .speed-button.right-button span {
  right: 0.5vw;
}

.control-bar .buttons {
  align-items: center;
  background-color: transparent;
  margin: 0 0 8vw;
  padding: 0;
  width: 60vw;
}

.control-bar .play-button,
.control-bar .next-button,
.control-bar .previous-button {
  align-items: center;
  border: 2px solid #333333;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.control-bar .play-button {
  width: 16vw;
  height: 16vw;
}

.control-bar .next-button,
.control-bar .previous-button {
  width: 12vw;
  height: 12vw;
}

.control-bar .play-button .adm-image {
  width: 10vw;
  height: 10vw;
}

.control-bar .next-button .adm-image-img,
.control-bar .previous-button .adm-image {
  width: 7.5vw;
  height: 7.5vw;
}

.replay-index-page .adm-tabs-tab > p,
.replay-index-page .adm-tabs-tab > span {
  display: block;
  margin: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.replay-index-page {
  display: block;
  overflow: hidden;
  width: 100vw;
  height: 100%;
}

.replay-index-page .adm-tabs-tab > p {
  font-size: 17px;
  line-height: 24px;
}

.replay-index-page .adm-tabs-tab > span {
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
}

.replay-index-page .adm-tabs-content {
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  height: calc(calc(var(--vh, 1vh) * 100) - 62px);
  height: calc(100dvh - 62px);
}

.replay-index-page .adm-list {
  width: calc(100vw - 24px);
}

.replay-index-page .adm-list .adm-list-body {
  font-size: 15px;  
}

.replay-index-page .adm-list .adm-list-body .adm-list-item-content-main {
  color: #666666;
}

.replay-index-page .adm-list .adm-list-body .selectable .adm-list-item-content-main {
  color: #1677ff;
}

.replay-tests-page {
  padding: 12px;
}

.tests-title .adm-card-header-title {
  color: #1677ff;
}

.tests-title .adm-card-header-title p {
  font-size: 15px;
  line-height: 22px;
  margin: 0;
}

.tests-title .adm-card-header-title span {
  display: block;
  font-size: 13px;
  line-height: 18px;
  opacity: 0.6;
}

.tests-body .adm-list-item {
  font-size: 15px;
  line-height: 22px;
  padding-left: 30px;
  position: relative;
}

.tests-body .adm-list-item .adm-list-item-content {
  padding-right: 0;
}

.tests-body .adm-list-item .adm-list-item-content span.red {
  color: #ff3141;
}

.tests-body .adm-list-item:nth-child(2) {
  border-top: 1px solid #eeeeee;
}

.tests-body .adm-list-item:nth-child(2) .adm-list-item-content {
  border-top-style: none;
}

.tests-body .adm-list-item .adm-list-item-content-main .expand-icon {
  color: #1677ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30px;
}

.tests-body .adm-list-item .adm-list-item-content-main .antd-mobile-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.tests-body .adm-list-item .adm-list-item-content-main .replay-test-index {
  color: #cccccc;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 0;
  text-align: center;
  width: 30px;
}

.replay-page {
  padding: 0;
}

.replay-page .adm-result-page {
  background-color: #ffffff;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.replay-page .adm-result-page-content {
  top: -14px;
}

.replay-page .progress-text {
  color: #1677ff;
  font-weight: 500;
  text-align: center;
}

.replay-page .adm-capsule-tabs-content .adm-list-body {
  font-size: 15px;
}

.replay-page .adm-capsule-tabs-content .incorrect-words-list .adm-list-item-content {
  padding-right: 40px;
}

.replay-page .adm-capsule-tabs-content .incorrect-words-list .adm-list-item-content-main {
  position: relative;
}

.replay-page .adm-capsule-tabs-content .incorrect-words-list .sound-box {
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -40px;
  bottom: 0;
  width: 40px;
}

.replay-page .adm-capsule-tabs-content .incorrect-words-list .sound {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.replay-page .adm-capsule-tabs-content .words-list .adm-list-item-content-main {
  position: relative;
}

.replay-page .adm-capsule-tabs-content .words-list .adm-list-item-content-main .antd-mobile-icon {
  color: #1677ff;
  position: absolute;
  top: 16px;
  bottom: 0;
  left: 0;
}

.replay-page .adm-capsule-tabs-content .words-list .adm-list-item-content-main span {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 0 0 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100vw - 72px);
}

.replay-page .adm-capsule-tabs-content .words-list.replaying .adm-list-item-content-main span {
  filter: blur(5px);
}
